<div id="container-form" class=" w-full max-h-max flex flex-1 flex-col px-5">
  <div class="flex justify-between items-center gap-4 mb-4">
    <div>
      <h1 class="font-black text-[20px] text-black">Usuário</h1>
      <h6 class="text-zinc-400 font-light text-sm my-2">Preencha os dados e crie um novo usuário</h6>
    </div>
  </div>

  <div class="w-full h-full flex gap-4 max-custom-min:flex-col">
    <div class="w-[60%] max-custom-min:w-full">
      <form [formGroup]="formGroup">
        <div class="flex w-full flex-col">
          <div class="flex flex-col gap-2 w-[100%]">
            <div class="flex gap-3 border-t-2 border-gray-200 pt-3 mb-3">
              <div class="flex flex-col w-[33%]">
                <label class="text-black mb-2">Nome *</label>
                <input class="input-field" maxlength="100" formControlName="name" placeholder="ex: Fernanda Pimentel">
                <field-error-component [field]="'name'" [label]="'Nome'" [formGroup]="formGroup">
                </field-error-component>
              </div>

              <div class="flex flex-col w-[33%]">
                <label class="text-black mb-2">Email *</label>
                <div id="input-phone" class="bg-white flex pl-2 items-center w-[100%] h-[100%] border rounded-lg">
                  <mat-icon class="mr-2 text-gray-400">email</mat-icon>
                  <input class="w-[100%] h-[100%] rounded-xl bg-white outline-none" maxlength="100"
                    formControlName="email" placeholder="exemplo@email.com">
                </div>
                <field-error-component [field]="'email'" [label]="'Email'" [formGroup]="formGroup">
                </field-error-component>
              </div>
              <div class="flex flex-col w-[33%]">
                <label class="text-black mb-2">Telefone *</label>
                <div id="input-phone" class="bg-white flex pl-2 items-center w-[100%] h-[100%] border rounded-lg">
                  <mat-icon class="mr-2 text-gray-400">phone</mat-icon>
                  <input class="w-[100%] h-[100%] rounded-xl bg-white outline-none" [mask]="'(00) 00000-0000'"
                    formControlName="phone" placeholder="(00) 00000-0000">
                </div>
                <field-error-component [field]="'phone'" [label]="'Telefone'" [formGroup]="formGroup">
                </field-error-component>
              </div>
            </div>

            <div class="flex gap-3 border-t-2 border-gray-200 pt-3 mb-3">
              <div class="flex flex-col w-[50%]">
                <label class="text-black mb-2">CPF</label>
                <input class="input-field" [mask]="'000.000.000-00'" formControlName="tin" placeholder="000.000.000-00">
              </div>

              <div class="flex flex-col w-[50%]">
                <label class="text-black mb-2">Data de nascimento</label>
                <div id="input-phone" class="bg-white flex pl-2 items-center w-[100%] h-[100%] border rounded-lg">
                  <input class="w-[100%] h-[100%] rounded-xl bg-white outline-none" [matDatepicker]="picker"
                    formControlName="birthDate" placeholder="00/00/0000">
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div id="slide-form" class="pt-3 border-t-2 flex gap-4 border-gray-200 mt-3">
          <div class="w-[30%] h-auto mt-2">
            <h1 class="font-black text-[20px] text-black">Permissões</h1>
            <div class="flex w-[100%] p-2 mt-2">
              <mat-slide-toggle class="example-slide" #slideToggleAdmin formControlName="roleAdmin"
                (change)="toogleRoles($event.checked, 'ADMIN')" color="#4213F6">
                <span class="text-[#344054] ml-2 text-[1rem]">Administrador</span>
              </mat-slide-toggle>
            </div>
            <div class="flex w-[100%] p-2 mt-2">
              <mat-slide-toggle class="example-slide" #slideToggleSupervisor formControlName="roleSupervisor"
                (change)="toogleRoles($event.checked, 'SUPERVISOR')" color="#4213F6">
                <span class="text-[#344054] ml-2 text-[1rem]">Supervisor</span>
              </mat-slide-toggle>
            </div>
            <div class="flex w-[100%] p-2 mt-2">
              <mat-slide-toggle class="example-slide" #slideToggleAttendant formControlName="roleAttendant"
                (change)="toogleRoles($event.checked, 'ATTENDANT')" color="#4213F6">
                <span class="text-[#344054] ml-2 text-[1rem]">Atendente</span>
              </mat-slide-toggle>
            </div>
          </div>
          <div class="w-[50%] h-auto mt-2">
            <h1 class="font-black text-[20px] text-black">Regras do atendente</h1>
            <div class="flex w-[100%] p-2 mt-2">
              <mat-slide-toggle class="example-slide" formControlName="signatureConversation" color="#4213F6">
                <span class="text-[#344054] ml-2 text-[1rem]">Nome visível para cliente</span>
              </mat-slide-toggle>
            </div>
            <div class="flex w-[100%] p-2 mt-2 flex-col">
              <mat-slide-toggle class="example-slide" formControlName="addLeads" color="#4213F6">
                <span class="text-[#344054] ml-2 text-[1rem]">Adicionar Contatos</span>
              </mat-slide-toggle>
              <mat-slide-toggle class="example-slide mt-4" formControlName="clientVisibilitys" color="#4213F6">
                <span class="text-[#344054] ml-2 text-[1rem]">Ocultar número de telefone</span>
              </mat-slide-toggle>
            </div>
            <div class="flex w-[100%] p-2 mt-2">
              <mat-slide-toggle class="example-slide" formControlName="deleteMessage" color="#4213F6">
                <span class="text-[#344054] ml-2 text-[1rem]">Apagar mensagens (API não oficial)</span>
              </mat-slide-toggle>
            </div>
            <div class="flex w-[100%] p-2 mt-2">
              <mat-slide-toggle class="example-slide" formControlName="viewFullHistory" color="#4213F6">
                <span class="text-[#344054] ml-2 text-[1rem]">Acessar histórico de todos os atendimentos</span>
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="w-[40%] h-[28.125rem] max-custom-min:w-full">
      <div class="loading-container">
        <loading-component class="loading" *ngIf="loadingSpinner$ | async"></loading-component>
        @if (user.image) {
        <div class="w-full h-[100%] flex flex-col items-center justify-center">
          <img [src]="userImage()" alt="Sua imagem" class="w-full h-[100%] rounded-full object-contain">
          <button (click)="removeMidia()"
            class="bg-red-600 text-white flex items-center rounded mt-[10px]"><mat-icon>clear</mat-icon></button>
        </div>
        }
        @if (!user.image) {
        <div class="flex items-center justify-center w-[100%]">
          <label for="dropzone-file"
            class="flex flex-col items-center justify-center w-[100%] h-[100%] h-42 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white">
            <div class="flex flex-col items-center justify-center pt-5 pb-6">
              <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="4.5" y="4" width="48" height="48" rx="24" fill="#E6E6FF" />
                <rect x="4.5" y="4" width="48" height="48" rx="24" stroke="#F1F1FF" stroke-width="8" />
                <g clip-path="url(#clip0_6074_436)">
                  <path
                    d="M32.5 32L28.5 28M28.5 28L24.5 32M28.5 28V37M36.89 34.39C37.8653 33.8583 38.6358 33.0169 39.0798 31.9986C39.5239 30.9804 39.6162 29.8432 39.3422 28.7667C39.0682 27.6901 38.4434 26.7355 37.5666 26.0534C36.6898 25.3714 35.6108 25.0007 34.5 25H33.24C32.9373 23.8292 32.3731 22.7423 31.5899 21.821C30.8067 20.8996 29.8248 20.1678 28.7181 19.6806C27.6113 19.1933 26.4085 18.9633 25.2001 19.0079C23.9916 19.0524 22.809 19.3703 21.7411 19.9376C20.6732 20.505 19.7479 21.3071 19.0346 22.2836C18.3213 23.26 17.8387 24.3855 17.6229 25.5754C17.4072 26.7652 17.4641 27.9885 17.7892 29.1532C18.1143 30.318 18.6992 31.3938 19.5 32.3"
                    stroke="#4213F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_6074_436">
                    <rect width="24" height="24" fill="white" transform="translate(16.5 16)" />
                  </clipPath>
                </defs>
              </svg>
              <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span
                  class="font-semibold text-[#4213F6] text-xl">Clique aqui para carregar</span> ou arraste e solte
              </p>
              <p class="text-xs text-gray-500 dark:text-gray-400">PNG, JPG ou JPEG (MAX. 800x400px)</p>
            </div>
            <input id="dropzone-file" type="file" class="hidden" (change)="onSelectImagem($event)" />
          </label>
        </div>
        }
      </div>
    </div>
  </div>
  <div class="flex flex-1 border-t-2 border-gray-200 justify-end items-center w-full gap-4 mt-3 mb-4">
    @if (!isNew) {
    <button class="h-[45px] mt-3 px-14 flex justify-center items-center rounded-lg text-white font-normal bg-red-600"
      (click)="deleteUser()">Remover usuário</button>
    }
    <button
      class="h-[45px] mt-3 px-14 flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100"
      (click)="back()">Cancelar</button>
    <button
      class="h-[45px] mt-3 px-14 flex justify-center items-center rounded-lg  text-white font-normal   bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none"
      (click)="save()">Salvar</button>
  </div>
</div>